import Echo from "laravel-echo";
import Pusher from "pusher-js";

window.Pusher = Pusher;

window.Echo = new Echo({
  broadcaster: "pusher",
  key: "RequestConnector",
  wsHost: "192.168.0.123",
  wsPort: 6005,
  cluster: "mt1",
  forceTLS: false,
});
